import * as React from "react";
import { Seo } from "../../components/Seo";
import { Header, Footer } from "../../components/layout/index";
import illustration from "../../images/demander_une_demo.png";
import { Link } from "gatsby";
import { ArrowLeftIcon } from "@heroicons/react/solid";

const ContactPage = props => {
  return (
    <>
      <Seo title="Demander une démo" />
      <Header />
      <div className="flex flex-col w-full items-center pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <h1 className="text-3xl tracking-tight font-extrabold sm:text-4xl text-action-600">
          C'est noté !
        </h1>
        <p className="mt-8 text-lg text-gray-500 text-center">
          Nous avons bien reçu votre demande de démo.
        </p>
        <div className="w-64 sm:w-80 h-auto flex justify-center mt-10">
          <img
            src={illustration}
            alt="Omnidoc, plateforme de télé-expertise - Demande de démo"
          />
        </div>
        <Link
          to="/"
          className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-action-700 bg-action-100 hover:bg-action-200 mt-10"
        >
          <span className="mr-1">
            <ArrowLeftIcon className="w-5" strokeWidth="0.5" />
          </span>
          Retourner à l'accueil
        </Link>
      </div>
      <Footer />
    </>
  );
};

export default ContactPage;
